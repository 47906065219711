<script setup lang="ts">
import dayjs from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();

const props = defineProps<{
	image: string;
	title: string;
	subTitle: string;
	entries: number;
	type?: Promotion["type"];
	dateFinish?: string;
	isAvailable?: boolean;
}>();

const timeRemaining = dayjs(props.dateFinish).diff(dayjs(), "d") > 1 ? "D[d] HH[h] mm[m]" : "HH[:]mm[:]ss";

const { durationLeft, durationExceeded } = useCountdown({
	timestamp: props.dateFinish || "",
	formatToken: timeRemaining
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 135,
			height: 246,
			src: image,
			format: 'avif',
			loading: 'lazy',
			alt: 'season-promotion-img'
		}"
		width="328px"
		height="246px"
		class="card-season"
	>
		<div class="badge-wrapper">
			<ABadge background="var(--goeteborg)" variant="info" autosize>
				<AText variant="ternopil" class="text-carabanchel badge" :modifiers="['bold', 'uppercase']">
					{{ subTitle }}
				</AText>
			</ABadge>
		</div>
		<div class="free">
			<ASvg name="48/free" class="icon" />
			<AText class="text-tlalnepantla free-text text-carabanchel" :modifiers="['semibold', 'uppercase']">
				{{ t("Free") }}
			</AText>
		</div>
		<AText variant="topeka" :modifiers="['bold', 'uppercase']">
			{{ title }}
		</AText>
		<AText v-if="!durationExceeded" variant="topeka" :modifiers="['semibold']" class="label-time">
			{{ t("Ends in:") }}
			<AText :modifiers="['extrabold']" class="label-time" gradient="var(--goeteborg)">
				<span>{{ durationLeft }}</span>
			</AText>
		</AText>
		<MPrizeFund v-if="entries" variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="24">
			<AText variant="tanzay" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
				{{ numberFormat(entries) }}
			</AText>
		</MPrizeFund>
		<AButton variant="primary" class="button" size="s" data-tid="promo-season">
			<AText :modifiers="['semibold']" class="text-tlalnepantla">
				{{ isAvailable ? t("Start Climbing") : t("Unlock") }}
			</AText>
			<NuxtIcon v-if="!isAvailable" name="20/ticket" filled />
		</AButton>
	</ACard>
</template>

<style lang="scss" scoped>
.card-season {
	padding: 16px;
	justify-content: center;
	gap: 8px;
	background: var(--gadwal);

	&:deep(.prize) {
		gap: 4px;
	}

	.text-count {
		text-shadow: 0 4px 4px var(--coronel);
	}

	.subtitle {
		max-width: 180px;
	}

	.button {
		min-width: 140px;
		width: max-content;
		gap: 2px;

		.nuxt-icon:deep(svg) {
			margin: 0;
			font-size: 18px;
		}
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 4px 16px 0;

		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
		}
	}

	.badge-wrapper {
		min-height: 22px;
	}

	.badge {
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
	}
}
</style>
